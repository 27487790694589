import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ValidationError } from '../models';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  private _translations: any = {};
  private _subjectTranslationsLoaded: Subject<any> = new Subject<any>();

  public translationsLoaded$: Observable<any> = this._subjectTranslationsLoaded.asObservable();

  constructor(
    private _http: HttpClient
  ) { }

  public init(culture: string): Promise<void> {
    return firstValueFrom(
      this._http.get<any>(
        `assets/translations/translation-${culture.toLocaleLowerCase()}.json`,
        {
          headers: new HttpHeaders({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Pragma: 'no-cache',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Expires: '0'
          })
        })
        .pipe(
          tap((val: any) => {
            this._translations = val || {};
            this._subjectTranslationsLoaded.next(null);
          })
        )
    );
  }

  public translate(value: string, group: string, ...replaceValues: any[]): string {
    if (!value) {
      return '';
    }

    const result = `${value}(${group}) missing`;
    if (!this._translations || !this._translations[group]) {

      return result;
    }

    let entry = this._translations[group][value] as string;
    if (!entry) {

      return result;
    }

    if (replaceValues) {
      for (let i = 0; i < replaceValues.length; i++) {
        const replaceValue = replaceValues[i];
        entry = entry.replace(`%${i}`, replaceValue.toString());
      }
    }

    return entry;
  }

  public getByGroupName(group: string): any {
    if (!this._translations || !this._translations[group]) {

      return null;
    }

    return this._translations[group];
  }

  public formatError(validationErrors: ValidationError[] | undefined, group: string, translationMapping: { [key: string]: string } | null = null): string {
    if (!validationErrors || validationErrors.length === 0) {
      return '';
    }

    const validationError = validationErrors[0];
    let furtherErrors = '';
    if (validationErrors.length === 2) {
      furtherErrors = ` (${this.translate('FurtherError', 'validation').replace('%0', (validationErrors.length - 1).toString(10))})`;
    } else if (validationErrors.length > 2) {
      furtherErrors = ` (${this.translate('FurtherErrors', 'validation').replace('%0', (validationErrors.length - 1).toString(10))})`;
    }

    let propertyName = validationError.propertyName.endsWith('Id')
      ? validationError.propertyName.substring(0, validationError.propertyName.length - 2)
      : validationError.propertyName;

    if (translationMapping && propertyName in translationMapping) {
      propertyName = translationMapping[propertyName];
    }

    let property = this.translate(propertyName, group);
    if (property.endsWith('missing')) {
      property = propertyName;
    }

    const errorMessage = this.translate(validationError.errorType, 'errors');
    if (errorMessage.endsWith('missing')) {
      return `${property}: ${validationError.errorType} ${furtherErrors}`;
    }

    if (errorMessage.indexOf('%0') < 0) {
      return `${property}: ${errorMessage} ${furtherErrors}`;
    }

    return errorMessage.replace('%0', property) + furtherErrors;
  }
}
