import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Permissions } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private _profileLoadedSubject = new Subject<any>();
  private _userProfile: any;
  private _isInitializing = true;
  private _firstLoad = true;
  private _currentAuthConfig: AuthConfig = {};
  private _appMetaData: any;

  public profileLoaded$ = this._profileLoadedSubject.asObservable();

  constructor(
    private _oauthService: OAuthService,
    private _router: Router
  ) {

  }

  public set redirectUrl(url: string) {
    localStorage.setItem('redirectUrl', url);
  }

  public get redirectUrl(): string {
    return localStorage.getItem('redirectUrl') || '';
  }

  public isInitialized(): boolean {
    return !!this._userProfile;
  }

  public isAuthenticated(): boolean {
    return this._oauthService.hasValidIdToken();
  }

  public getUsername(): string {
    return this._userProfile?.email;
  }

  public getUserId(): number | undefined {
    return parseInt(this._userProfile?.sub);
  }

  public getUserThumbnail(): string {
    return this._userProfile?.picture;
  }

  public getUserCulture(): string {
    return this._userProfile?.PreferredLanguage || 'de-DE';
  }

  public hasValidAccountId(): boolean {
    return !!this._appMetaData.AccountId;
  }

  public showTenantControls(): boolean {
    return !!this._appMetaData.TenantId
      && this._appMetaData.TenantId === this._appMetaData.EshavaTenantReferencId;
  }

  public getAccountId(): number {
    return this._appMetaData.AccountId;
  }

  public hasPermission(permissions: Permissions[]): boolean {
    if (!permissions || permissions.length === 0) {
      return true;
    }

    if (!this._appMetaData.PermissionString) {
      return false;
    }

    for (const p of permissions) {
      if (this._appMetaData.PermissionString.includes(String.fromCharCode(p))) {
        return true;
      }
    }

    return false;
  }

  public signOut(): void {
    this._oauthService.logOut();
  }

  public silentRefresh(): void {
    this._oauthService.silentRefresh();
  }

  public redirectToSignIn(url: string): void {
    this.clearLoginInfosFromSessionStorage();

    this.configure(this._currentAuthConfig);
    this.redirectUrl = url;
    this._router.navigate(['/']);
  }

  public configure(authConfig: AuthConfig): void {
    this._oauthService.configure(authConfig);
    this._currentAuthConfig = authConfig;
    this._oauthService.tokenValidationHandler = new JwksValidationHandler();
    this._oauthService.loadDiscoveryDocumentAndLogin({
      disableOAuth2StateCheck: true,
      onLoginError: (_: unknown) => {
        this._router.navigate(['/']);
      }
    }).then(
      success => {
        if (success) {
          this.loadUserProfile();
          this._isInitializing = false;
          if ((this._router.url === '/' || this._router.url.indexOf('/#id_token') === 0)) {
            const redirectUrl: string = this.redirectUrl;
            if (redirectUrl && redirectUrl !== '/') {
              this.redirectUrl = '';
              this._router.navigate([redirectUrl]);
            } else {
              this._router.navigate(['/']);
            }
          }
        }
      },
      _ => {
        window.location.href = window.location.protocol + '//' + window.location.host + '/';
      }
    );

    if (this._firstLoad) {
      this._oauthService.setupAutomaticSilentRefresh();

      this._oauthService.events
        .pipe(filter(e => e.type === 'token_received'))
        .subscribe(_ => {
          if (!this._isInitializing) {
            this.loadUserProfile();
          }
        });

      this._oauthService.events.subscribe(event => {
        if (event.type === 'silent_refresh_timeout'
          || event.type === 'silent_refresh_error'
          || event.type === 'token_error') {
          console.log(JSON.stringify(event));
          this.redirectToSignIn(this.redirectUrl);
        }
      });
    }

    this._firstLoad = false;
  }

  private loadUserProfile(): void {
    this._oauthService.loadUserProfile().then(
      userProfile => {
        this._userProfile = (userProfile as any).info;
        this._appMetaData = JSON.parse(this._userProfile.app_metadata || '{}');
        this._profileLoadedSubject.next(this._userProfile);
      }
    );
  }

  private clearLoginInfosFromSessionStorage(): void {
    sessionStorage.removeItem('nonce');
    sessionStorage.removeItem('id_token_expires_at');
    sessionStorage.removeItem('expires_at');
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('access_token_stored_at');
    sessionStorage.removeItem('id_token');
    sessionStorage.removeItem('id_token_stored_at');
    sessionStorage.removeItem('id_token_claims_obj');
    sessionStorage.removeItem('session_state');
    sessionStorage.removeItem('granted_scopes');
  }
}
