import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { EshavaScrollSpyActiveItem } from '../models';

class Bucket {
  anchor!: string;
  value!: number;
}

@Injectable({ providedIn: 'root' })
export class EshavaScrollSpyService {

  private _activeItems: { [key: string]: Bucket[] } = {};
  private _itemStatusChangedSubject = new Subject<EshavaScrollSpyActiveItem>();

  public itemStatusChanged$ = this._itemStatusChangedSubject.asObservable().pipe(distinctUntilChanged());

  constructor() { }

  public setActive(intersectionName: string, item: string, offset: number): void {
    let bucket = this._activeItems[intersectionName] || [];
    const index = bucket.findIndex(b => b.anchor === item);
    if (index < 0) {
      bucket.push({ anchor: item, value: offset });
    }

    bucket = bucket.sort((a, b) => a.value - b.value);
    if (bucket.length > 0) {
      this._itemStatusChangedSubject.next({ intersectionName, anchor: bucket[0].anchor });
    } else {
      this._itemStatusChangedSubject.next({ intersectionName, anchor: '' });
    }

    this._activeItems[intersectionName] = bucket;
  }

  public setInactive(intersectionName: string, item: string): void {
    const bucket = this._activeItems[intersectionName] || [];
    const index = bucket.findIndex(b => b.anchor === item);
    if (index >= 0) {
      bucket.splice(index, 1);
    }

    if (bucket.length > 0) {
      this._itemStatusChangedSubject.next({ intersectionName, anchor: bucket[0].anchor });
    } else {
      this._itemStatusChangedSubject.next({ intersectionName, anchor: '' });
    }
  }
}
