import {
  ApplicationTheme
}

  from '../models';

export const darkTheme: ApplicationTheme = {
  '--background-color': '#22252b',
  '--color': '#fff',
  '--primary': '#ff6516',
  '--green': '#ff6516',
  '--red': '#df4249',
  '--menu-background-color': '#191b20',
  '--menu-background-color-active': '#ff6516',
  '--menu-color': '#ffffff',
  '--menu-color-active': '#ff6516',
  '--menu-border-color': '#191b20',
  '--menu-border-color-active': '#ff6516',
  '--button-color': '#fff',
  '--button-background-color': '#464d59',
  '--button-border-color': '#191b20',
  '--loading-indicator-background-color': '#191b20',
  '--card-border-color': '#505050',
  '--card-background-color': '#30343d',
  '--card-color-headline': '#fff',
  '--card-color-primary': '#fff',
  '--card-color-secondary': '#b0b0b0',
  '--card-font-size-headline': '1.25rem',
  '--card-font-size-primary': '1rem',
  '--card-font-size-secondary': '0.875rem',
  '--eshava-grid-cell-background-color': '#30343d',
  '--eshava-grid-font-color': '#c0c0c0',
  '--eshava-grid-border-color': '#505050',
  '--eshava-grid-popup-background-color': '#30343d',
  '--eshava-grid-popup-border-color': '#c0c0c0',
  '--eshava-grid-popup-font-color': '#c0c0c0',
  '--eshava-grid-popup-input-background-color': '#c0c0c0',
  '--eshava-grid-popup-input-font-color': '#30343d',
  '--eshava-grid-overlay-background-color': '#30343d50',
  '--eshava-grid-overlay-color': '#c0c0c0',
  '--eshava-grid-primary-color': '#ff6516',
  '--eshava-grid-secondary-color': '#fff',
  '--eshava-controls-primary-color': '#ff6516',
  '--eshava-controls-secondary-color': '#fff',
  '--eshava-controls-input-background-color': '#30343d',
  '--eshava-controls-input-border-color': '#c0c0c0',
  '--eshava-controls-input-border-left-width': '0',
  '--eshava-controls-input-border-right-width': '0',
  '--eshava-controls-input-border-top-width': '0',
  '--eshava-controls-input-border-bottom-width': '0.0625rem',
  '--eshava-controls-input-font-color': '#fff',
  '--eshava-controls-input-invalid-color': '#f0b362',
  '--eshava-controls-input-disabled-color': '#6c757d',
  '--eshava-controls-input-label-color': '#fff',
  '--eshava-controls-input-switch-background-color': '#a0a0a0',
  '--eshava-controls-input-switch-color': '#e0e0e0',
  '--eshava-controls-input-switch-focus-color': '#30343d',
  '--eshava-controls-tag-box-shadow': '#00000033',
  '--eshava-controls-tag-font-color-dark': '#f8f9fa',
  '--eshava-controls-tag-font-color-dark-hover': '#f8f9fa',
  '--eshava-controls-tag-background-color-dark': '#212529',
  '--eshava-controls-tag-font-color-gray': '#f8f9fa',
  '--eshava-controls-tag-font-color-gray-hover': '#212529',
  '--eshava-controls-tag-background-color-gray': '#6c757d',
  '--eshava-controls-tag-font-color-red': '#f8f9fa',
  '--eshava-controls-tag-font-color-red-hover': '#212529',
  '--eshava-controls-tag-background-color-red': '#dc3545',
  '--eshava-controls-tag-font-color-yellow': '#f8f9fa',
  '--eshava-controls-tag-font-color-yellow-hover': '#212529',
  '--eshava-controls-tag-background-color-yellow': '#ffc107',
  '--eshava-controls-tag-font-color-green': '#f8f9fa',
  '--eshava-controls-tag-font-color-green-hover': '#212529',
  '--eshava-controls-tag-background-color-green': '#198754',
  '--message-banner-error-background-color': '#f7b500',
  '--message-banner-error-color': '#000000',
  '--message-banner-critical-background-color': '#9a2515',
  '--message-banner-critical-color': '#ffffff',
  '--message-banner-information-color': '#000000',
  '--message-banner-information-background-color': '#a2e9fd',
  '--context-menu-border-color': '#505050',
  '--context-menu-color': '#fff',
  '--context-menu-color-hover': '#30343d',
  '--context-menu-color-disabled': '#B3B3B3',
  '--context-menu-background-color': '#22252b',
  '--context-menu-box-shadow-color': '#ffffff33'
}

  ;

export const lightTheme: ApplicationTheme = {
  '--background-color': '#f9f9f9',
  '--color': '#000',
  '--primary': '#ff6516',
  '--green': '#ff6516',
  '--red': '#df4249',
  '--menu-background-color': '#f0f0f0',
  '--menu-background-color-active': '#ff6516',
  '--menu-color': '#000000',
  '--menu-color-active': '#ff6516',
  '--menu-border-color': '#e0e0e0',
  '--menu-border-color-active': '#ff6516',
  '--button-color': '#000',
  '--button-background-color': '#e0e0e0',
  '--button-border-color': '#e0e0e0',
  '--loading-indicator-background-color': '#a5cada',
  '--card-border-color': '#e0e0e0',
  '--card-background-color': '#fafafa',
  '--card-color-headline': '#000000',
  '--card-color-primary': '#000',
  '--card-color-secondary': '#505050',
  '--card-font-size-headline': '1.25rem',
  '--card-font-size-primary': '1rem',
  '--card-font-size-secondary': '0.875rem',
  '--eshava-grid-cell-background-color': '#f9f9f9',
  '--eshava-grid-font-color': '#22252b',
  '--eshava-grid-border-color': '#c0c0c0',
  '--eshava-grid-popup-background-color': '#fafafa',
  '--eshava-grid-popup-border-color': '#606060',
  '--eshava-grid-popup-font-color': '#000000',
  '--eshava-grid-popup-input-background-color': '#fafafa',
  '--eshava-grid-popup-input-font-color': '#000000',
  '--eshava-grid-overlay-background-color': '#e0e0e050',
  '--eshava-grid-overlay-color': '#000',
  '--eshava-grid-primary-color': '#ff6516',
  '--eshava-grid-secondary-color': '#000',
  '--eshava-controls-primary-color': '#ff6516',
  '--eshava-controls-secondary-color': '#000',
  '--eshava-controls-input-background-color': '#fafafa',
  '--eshava-controls-input-border-color': '#767676',
  '--eshava-controls-input-border-left-width': '0rem',
  '--eshava-controls-input-border-right-width': '0rem',
  '--eshava-controls-input-border-top-width': '0rem',
  '--eshava-controls-input-border-bottom-width': '0.0625rem',
  '--eshava-controls-input-font-color': '#000',
  '--eshava-controls-input-invalid-color': '#f0b362',
  '--eshava-controls-input-disabled-color': '#6c757d',
  '--eshava-controls-input-label-color': '#000',
  '--eshava-controls-input-switch-background-color': '#e0e0e0',
  '--eshava-controls-input-switch-color': '#fafafa',
  '--eshava-controls-input-switch-focus-color': '#f8f8f8',
  '--eshava-controls-tag-box-shadow': '#00000033',
  '--eshava-controls-tag-font-color-dark': '#f8f9fa',
  '--eshava-controls-tag-font-color-dark-hover': '#f8f9fa',
  '--eshava-controls-tag-background-color-dark': '#212529',
  '--eshava-controls-tag-font-color-gray': '#f8f9fa',
  '--eshava-controls-tag-font-color-gray-hover': '#f8f9fa',
  '--eshava-controls-tag-background-color-gray': '#6c757d',
  '--eshava-controls-tag-font-color-red': '#f8f9fa',
  '--eshava-controls-tag-font-color-red-hover': '#f8f9fa',
  '--eshava-controls-tag-background-color-red': '#dc3545',
  '--eshava-controls-tag-font-color-yellow': '#f8f9fa',
  '--eshava-controls-tag-font-color-yellow-hover': '#f8f9fa',
  '--eshava-controls-tag-background-color-yellow': '#ffc107',
  '--eshava-controls-tag-font-color-green': '#f8f9fa',
  '--eshava-controls-tag-font-color-green-hover': '#f8f9fa',
  '--eshava-controls-tag-background-color-green': '#198754',
  '--message-banner-error-background-color': '#f7b500',
  '--message-banner-error-color': '#000000',
  '--message-banner-critical-background-color': '#9a2515',
  '--message-banner-critical-color': '#ffffff',
  '--message-banner-information-color': '#000000',
  '--message-banner-information-background-color': '#a2e9fd',
  '--context-menu-border-color': '#606060',
  '--context-menu-color': '#000',
  '--context-menu-color-hover': '#e0e0e0',
  '--context-menu-color-disabled': '#B3B3B3',
  '--context-menu-background-color': '#fff',
  '--context-menu-box-shadow-color': '#00000033'
}

  ;
