export const errorMessageConstants = {
  alreadyUsedError: 'ALREADYUSEDERROR',
  createDataError: 'CREATEDATAERROR',
  deleteDataError: 'DELETEDATAERROR',
  errorDeleteData: 'ErrorDeleteData',
  errorSaveData: 'ErrorSaveData',
  invalidDataError: 'INVALIDDATAERROR',
  missingIdentifier: 'MISSINGIDENTIFIER',
  notExistingError: 'NOTEXISTINGERROR',
  readDataError: 'READDATAERROR',
  savingNotPossible: 'SavingNotPossible',
  UNEXPECTEDERROR: 'UNEXPECTEDERROR',
  updateDataError: 'UPDATEDATAERROR',
};
