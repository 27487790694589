import { Injectable } from '@angular/core';
import { ApplicationTheme } from '../models';
import { darkTheme, lightTheme } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private _theme: ApplicationTheme = darkTheme;

  public init(): void {
    const themeName = localStorage.getItem('eshava.assets.theme') || '';

    this._theme = this.map(themeName);
    this.setTheme(this.map(themeName), themeName || 'dark');
  }

  public getTheme(): ApplicationTheme {
    return this._theme;
  }

  public setTheme(theme: ApplicationTheme, themeName: string): void {
    localStorage.setItem('eshava.core.theme', themeName);

    Object.keys(theme).forEach(colorName => {
      const colorValue = theme[colorName as keyof ApplicationTheme];
      document.documentElement.style.setProperty(colorName, colorValue);
    });
  }

  private map(themeName: string): ApplicationTheme {
    switch (themeName) {
      case 'light':
        return lightTheme;
      default:
        return darkTheme;
    }
  }
}
