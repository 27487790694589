import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild {

  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const url = state.url;

    if (url.indexOf('id_token') === -1) {
      this._authenticationService.redirectUrl = url || '';
    }

    if (this._authenticationService.isInitialized()) {
      return of(this.canActivateInternal(route, state));
    }

    return this._authenticationService.profileLoaded$.pipe(map(_ => this.canActivateInternal(route, state)));
  }

  public canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return of(this.canActivateInternal(childRoute, state));
  }

  public canLoad(_: Route, __: UrlSegment[]): Observable<boolean> {
    return of(this.isAuthenticated(''));
  }

  private isAuthenticated(url: string): boolean {
    if (this._authenticationService.isAuthenticated()) {
      return true;
    }

    this._authenticationService.redirectToSignIn(url);

    return false;
  }

  private canActivateInternal(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    var validationResult = this.isAuthenticated(state.url)
      && this._authenticationService.hasValidAccountId();

    if (!validationResult || route.url.length === 0) {
      return validationResult;
    }

    if (!this._authenticationService.hasPermission((route.data as any).only ?? [])) {
      return this.checkSibling(route);
    }

    return true;
  }

  /**
  * Checks route siblings for valid route
  * @param route Route to check
  * @returns valid redirect or false
  */
  private checkSibling(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const parent = route.parent || {} as any;
    const children = parent.routeConfig?.children || [];
    let result = '';
    let index = 0;

    while (!result && index < children.length) {
      const childRoute = children[index];
      if (!childRoute.data) {
        result = this.getResolvedUrl(parent) + '/' + childRoute.path;
      } else {
        const only = childRoute.data.only || [];
        if (this._authenticationService.hasPermission(only)) {
          result = this.getResolvedUrl(parent) + '/' + childRoute.path;
        }
      }

      index++;
    }

    if (!result || result === '/') {
      return this._router.parseUrl('/403');
    }

    return this._router.parseUrl(result);
  }

  private getResolvedUrl(route: ActivatedRouteSnapshot): string {
    const url = route.pathFromRoot
      .map(v => v.url.map(segment => segment.toString()).filter(p => p).join('/'))
      .filter(p => p)
      .join('/');

    return url;
  }
}
