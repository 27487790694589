import { Directive, Input, NgZone, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { Permissions } from '../enums';
import { AuthenticationService } from '../services';

// tslint:disable-next-line: directive-selector
@Directive({ selector: '[hasPermission]' })
export class HasPermissionDirective implements OnDestroy, OnInit {

  private _permissions: Permissions[] = [];
  private _isHidden = true;
  private _subscription: Subscription = new Subscription();

  @Input()
  public set hasPermission(permissions: Permissions | Permissions[]) {
    if (!permissions) {
      this._permissions = [];
    } else if (!Array.isArray(permissions)) {
      this._permissions = [permissions as Permissions];
    } else {
      this._permissions = permissions as Permissions[] || [];
    }

    this.updateView();
  }

  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private _authenticationService: AuthenticationService,
    private _ngZone: NgZone
  ) { }

  public ngOnInit(): void {
    this._subscription = this._authenticationService.profileLoaded$.subscribe(_ => {
      setTimeout(() => {
        this._ngZone.run(() => {
          this.updateView();
        });
      }, 20);
    });
  }

  public ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  private updateView(): void {
    if (this._authenticationService.hasPermission(this._permissions)) {
      if (this._isHidden) {
        this._viewContainer.createEmbeddedView(this._templateRef);
        this._isHidden = false;
      }
    } else {
      this._isHidden = true;
      this._viewContainer.clear();
    }
  }
}
