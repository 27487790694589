<ul class="eshava-navigation"
    role="navigation">
  <ng-container *ngFor="let scrollSpyItem of scrollSpyItems">
    <li *ngIf="!scrollSpyItem.isHidden">
      <a [scrollSpyListItem]="scrollSpyItem"
         activeClass="eshava-active"
         href="{{ '#' + scrollSpyItem.anchor }}"
         [intersectionName]="scrollSpyItem.intersectionName ?? intersectionName"
         [attr.href]="url + '#' + scrollSpyItem.anchor"
         (click)="onClick($event, scrollSpyItem.anchor)"> {{ scrollSpyItem.text }} </a>
    </li>
  </ng-container>
</ul>
