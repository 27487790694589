import { AuthConfig } from 'angular-oauth2-oidc/public_api';

import { environment } from '../environments/environment';

/**
 * private interface for appSettings
 */
interface AppSettings {
  version: string;
  release: string;
  stage: string;
  isProduction: boolean;
  endpoints: {
    basicData: string;
    accounts: {
      users: string;
      roles: string;
      subscriptions: string;
    },
    applications: {
      applicationAccountRoles: string;
      applicationAccounts: string;
      applicationTypes: string;
      applications: string;
      machineToken: string;
      machineTokens: string;
      serviceHooks: string;
      subscription: string;
      subscriptions: string;
      userApplicationAccounts: string;
    };
    infrastructure: {
      logEntries: string;
      schedulerEvents: string;
      schedulerEventTypes: string;
      schedulerEnumValues: string;
    };
    permissions: {
      component: string;
      components: string;
      licenses: string;
      licenseTypes: string;
      modules: string;
      permissions: string;
      roleTemplates: string;
      subModule: string;
      subModules: string;
      synchronizations: string;
    },
    tenants: {
      tenants: string;
      accounts: string;
    };
    users: {
      users: string;
      userApplicationAccounts: string;
    };
  };
  authentication: {
    oidc: AuthConfig;
  };
}

export const appSettings: AppSettings = {
  version: '2.0.0',
  release: 'Release 28.04.2024',
  stage: environment.stage,
  isProduction: environment.production,
  authentication: {
    oidc: {
      issuer: environment.oidc.authority + '/',
      redirectUri: environment.baseUrls.uiUrl,
      postLogoutRedirectUri: environment.baseUrls.uiUrl,
      clientId: environment.oidc.clientId,
      scope: environment.oidc.scope,
      silentRefreshRedirectUri: environment.baseUrls.uiUrl + '/silent-refresh.html',
      clearHashAfterLogin: false,
      customTokenParameters: ['app_metadata', 'user_metadata', 'AccountId', 'email', 'name', 'sub', 'TenantId', 'PreferredLanguage'],
      requireHttps: environment.oidc.requireHttps
    }
  },
  endpoints: {
    basicData: environment.baseUrls.cardinalUrl + '/basicdata/',
    accounts: {
      users: environment.baseUrls.cardinalUrl + '/accounts/users/',
      roles: environment.baseUrls.cardinalUrl + '/accounts/roles/',
      subscriptions: environment.baseUrls.cardinalUrl + '/accounts/subscriptions/'
    },
    applications: {
      applicationAccountRoles: environment.baseUrls.cardinalUrl + '/applications/accounts/:accountId/roles/',
      applicationAccounts: environment.baseUrls.cardinalUrl + '/applications/accounts/',
      applications: environment.baseUrls.cardinalUrl + '/applications/',
      applicationTypes: environment.baseUrls.cardinalUrl + '/applicationtypes/',
      machineToken: environment.baseUrls.cardinalUrl + '/applications/:applicationId/machinetokens/',
      machineTokens: environment.baseUrls.cardinalUrl + '/applications/machinetokens/',
      serviceHooks: environment.baseUrls.cardinalUrl + '/servicehooks/',
      subscription: environment.baseUrls.cardinalUrl + '/tenants/:tenantId/subscriptions/',
      subscriptions: environment.baseUrls.cardinalUrl + '/subscriptions/',
      userApplicationAccounts: environment.baseUrls.cardinalUrl + '/applications/accounts/:accountId/users/',
    },
    infrastructure: {
      logEntries: environment.baseUrls.loggingUrl + '/logentries/',
      schedulerEnumValues: environment.baseUrls.schedulerUrl + '/enumvalues/',
      schedulerEvents: environment.baseUrls.schedulerUrl + '/schedulerevents/',
      schedulerEventTypes: environment.baseUrls.schedulerUrl + '/schedulereventtypes/'
    },
    permissions: {
      component: environment.baseUrls.cardinalUrl + '/permissions/modules/:moduleId/subModules/:subModuleId/components/',
      components: environment.baseUrls.cardinalUrl + '/permissions/components/',
      licenses: environment.baseUrls.cardinalUrl + '/permissions/licenses/',
      licenseTypes: environment.baseUrls.cardinalUrl + '/permissions/licensetypes/',
      modules: environment.baseUrls.cardinalUrl + '/permissions/modules/',
      permissions: environment.baseUrls.cardinalUrl + '/permissions/',
      subModule: environment.baseUrls.cardinalUrl + '/permissions/modules/:moduleId/subModules/',
      subModules: environment.baseUrls.cardinalUrl + '/permissions/subModules/',
      roleTemplates: environment.baseUrls.cardinalUrl + '/permissions/roletemplates/',
      synchronizations: environment.baseUrls.cardinalUrl + '/permissions/synchronizations/',
    },
    tenants: {
      accounts: environment.baseUrls.cardinalUrl + '/tenants/:tenantId/applicationaccounts/',
      tenants: environment.baseUrls.cardinalUrl + '/tenants/',
    },
    users: {
      users: environment.baseUrls.cardinalUrl + '/useraccounts/',
      userApplicationAccounts: environment.baseUrls.cardinalUrl + '/applications/accounts/users/',
    }
  }
};
