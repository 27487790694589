import { OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';
import { EshavaDialogAnimationRef } from './eshava-dialog-animation-ref';

export class EshavaDialogRef {
  private _afterClosedSubject = new Subject<any>();

  constructor(private _overlayRef: OverlayRef, private _animationRef: EshavaDialogAnimationRef) { }

  public close(result?: any): void {
    this._overlayRef.backdropElement?.classList.add('hide');
    this._animationRef.shouldAnimateExit().then(() => {
      this._overlayRef.dispose();
      this._afterClosedSubject.next(result);
      this._afterClosedSubject.complete();
    });
  }

  public afterClosed(): Observable<any> {
    return this._afterClosedSubject.asObservable();
  }
}
