import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../services';

/**
 * Translates given key for given group.
 *
 * @example
 * <span>{{ 'key' | translate:'group' }}</span>
 */
@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  constructor(
    private _translationService: TranslationService
  ) { }

  public transform(value: string | undefined, group: string, ...valueParameter: any[]): string {

    return this._translationService.translate(value || '', group, ...valueParameter);
  }

}
