import { NgModule } from '@angular/core';
import { CoreSharedModule } from '@core/shared';
import { AppShellComponent } from './components/app-shell/app-shell.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';

@NgModule({
  declarations: [
    AppShellComponent,
    ForbiddenComponent
  ],
  imports: [
    CoreSharedModule
  ],
  exports: [
    AppShellComponent
  ]
})
export class CoreModule { }
