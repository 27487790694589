import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-component-loading-indicator',
  templateUrl: './component-loading-indicator.component.html',
  styleUrls: ['./component-loading-indicator.component.scss'],
})
export class ComponentLoadingIndicatorComponent {

  @Input()
  public isVisible = false;
  @Input()
  public inline = false;

  constructor() { }
}
