import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-eshava-dialog-separator',
  templateUrl: './eshava-dialog-separator.component.html',
  styleUrls: ['./eshava-dialog-separator.component.scss']
})
export class EshavaDialogSeparatorComponent {
  @Input()
  public title = '';

  @Input()
  public active = false;
}
