<div class="banner-container  {{ message && message.level ? message.level : ''}}"
     [class.show]="showMessage"
     [class.border-bottom-radius]="borderBottomRadius">
      <div class="items-container">
            <svg-icon *ngIf="message"
                      src="{{message.icon}}"
                      class="eshava-core-svg status-icon"></svg-icon>
            <span class="title"
                  [innerHTML]="message ? message.title : null"></span>
            <span class="text1"
                  [innerHTML]="message ? message.text1 : null"></span>
            <span class="text2"
                  [innerHtml]="message ? message.text2 : null"></span>
            <svg-icon src="{{iconSet.cancel}}"
                      class="eshava-core-svg close-icon"
                      (click)="close()"></svg-icon>
      </div>
</div>