import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppShellComponent } from './modules/core/components/app-shell/app-shell.component';
import { AuthGuard, Permissions, iconSet } from '@core/shared'
import { ForbiddenComponent } from './modules/core/components/forbidden/forbidden.component';

const routes: Routes = [{
  path: '',
  canActivate: [AuthGuard],
  component: AppShellComponent,
  children: [{
    path: 'applications',
    canActivate: [AuthGuard],
    data: {
      titleKey: 'Applications',
      isMainNavigation: true,
      icon: iconSet.applications,
      only: [Permissions.CAN_USE_MODULE_APPLICATIONS]
    },
    loadChildren: () => import('@core/applications').then(m => m.CoreApplicationsModule)
  }, {
    path: 'permissions',
    canActivate: [AuthGuard],
    data: {
      titleKey: 'Permissions',
      isMainNavigation: true,
      icon: iconSet.permissions,
      only: [Permissions.CAN_USE_MODULE_PERMISSIONS]
    },
    loadChildren: () => import('@core/permissions').then(m => m.CorePermissionsModule)
  }, {
    path: 'tenants',
    canActivate: [AuthGuard],
    data: {
      titleKey: 'Tenants',
      isMainNavigation: true,
      icon: iconSet.tenants,
      only: [Permissions.CAN_USE_MODULE_TENANTS]
    },
    loadChildren: () => import('@core/tenants').then(m => m.CoreTenantsModule)
  }, {
    path: 'users',
    canActivate: [AuthGuard],
    data: {
      titleKey: 'Users',
      isMainNavigation: true,
      icon: iconSet.users,
      only: [Permissions.CAN_USE_MODULE_USERS]
    },
    loadChildren: () => import('@core/users').then(m => m.CoreUsersModule)
  }, {
    path: 'infrastructure',
    canActivate: [AuthGuard],
    data: {
      titleKey: 'Infrastructure',
      isMainNavigation: true,
      icon: iconSet.infrastructure,
      only: [Permissions.CAN_USE_MODULE_INFRASTRUCTURE]
    },
    loadChildren: () => import('@core/infrastructure').then(m => m.CoreInfrastructureModule)
  }, {
    path: '403',
    component: ForbiddenComponent
  }, {
    path: '',
    redirectTo: 'applications',
    pathMatch: 'full'
  }]
}, {
  path: '**',
  redirectTo: '/',
  pathMatch: 'full'
}];

export const mainNavigationRoutes: Routes = [{
  path: 'applications',
  data: {
    titleKey: 'Applications',
    isMainNavigation: true,
    icon: iconSet.applications,
    only: [Permissions.CAN_USE_MODULE_APPLICATIONS]
  }
}, {
  path: 'permissions',
  data: {
    titleKey: 'Permissions',
    isMainNavigation: true,
    icon: iconSet.permissions,
    only: [Permissions.CAN_USE_MODULE_PERMISSIONS]
  }
}, {
  path: 'tenants',
  data: {
    titleKey: 'Tenants',
    isMainNavigation: true,
    icon: iconSet.tenants,
    only: [Permissions.CAN_USE_MODULE_TENANTS]
  }
}, {
  path: 'users',
  data: {
    titleKey: 'Users',
    isMainNavigation: true,
    icon: iconSet.users,
    only: [Permissions.CAN_USE_MODULE_USERS]
  }
}, {
  path: 'infrastructure',
  data: {
    titleKey: 'Infrastructure',
    isMainNavigation: true,
    icon: iconSet.infrastructure,
    only: [Permissions.CAN_USE_MODULE_INFRASTRUCTURE]
  }
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
