import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { EshavaScrollSpyService } from '../../services';

@Directive({
  selector: '[scrollSpyTarget]'
})
export class EshavaScrollSpyTargetDirective implements OnInit, OnDestroy, AfterViewInit {

  private _show = false;
  private _subscriptions = new Subscription();
  private _intersectionObserver!: IntersectionObserver;

  @Input()
  public scrollSpyTarget = '';

  @Input()
  public activeClass = 'eshava-active';

  @Input()
  public intersectionName = 'intersectionRoot';

  @Input()
  public intersectionRoot!: HTMLElement;

  constructor(
    private _el: ElementRef,
    private _scrollSpyService: EshavaScrollSpyService,
    private _renderer: Renderer2
  ) { }

  public ngOnInit(): void {
    this._subscriptions.add(this._scrollSpyService.itemStatusChanged$.subscribe(
      item => {
        if (item.intersectionName === this.intersectionName) {
          this.setClass(item.anchor === this.scrollSpyTarget);
        }
      }
    ));
  }

  public ngAfterViewInit(): void {
    if (this._intersectionObserver) {
      this._intersectionObserver.disconnect();
    }
    this._intersectionObserver = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => this.observerCallback(entries),
      {
        root: this.intersectionRoot,
        threshold: [0.01]
      }
    );
    this._intersectionObserver.observe(this._el.nativeElement);
  }

  public ngOnDestroy(): void {
    if (this._subscriptions) {
      this._subscriptions.unsubscribe();
      this._subscriptions = new Subscription();
    }
    if (this._intersectionObserver) {
      this._scrollSpyService.setInactive(this.intersectionName, this._el.nativeElement.id);
      this._intersectionObserver.disconnect();
    }
  }

  private observerCallback(entries: IntersectionObserverEntry[]): void {
    (entries || []).forEach(item => {
      if (item.isIntersecting) {
        this._scrollSpyService.setActive(this.intersectionName, item.target.id, this._el.nativeElement.offsetTop);
      } else {
        this._scrollSpyService.setInactive(this.intersectionName, item.target.id);
      }
    });
  }

  private setClass(show: boolean): void {
    if (!this.activeClass || !this._el) {
      return;
    }
    if (show === this._show) {
      return;
    }

    if (show) {
      this._renderer.addClass(this._el.nativeElement.childNodes[0], this.activeClass);
    } else {
      this._renderer.removeClass(this._el.nativeElement.childNodes[0], this.activeClass);
    }
    this._show = show;
  }
}
