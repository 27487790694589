import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';

import { errorMessageConstants } from '../../constants';
import { BannerMessageLevel } from '../../enums';
import { BannerMessage, ErrorResponse, eshavaDialogData, EshavaDialogRef } from '../../models';
import { TranslationService } from '../../services';

@Component({
  selector: 'lib-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription = new Subscription();

  public isHtmlContent = false;
  public dialogTitle: string = '';
  public dialogMessage: SafeHtml | undefined;
  public primaryButtonTitle: string = '';
  public secondaryButtonTitle: string = '';
  public showLoadingIndicator = true;
  public errorMessage: BannerMessage | undefined;

  constructor(
    @Inject(eshavaDialogData) private _templateData: {
      dialogTitle: string,
      dialogMessage: string,
      primaryButtonTitle: string,
      secondaryButtonTitle: string,
      isHtmlContent: boolean
      confirm: () => Observable<BannerMessage>
    },
    private _dialogRef: EshavaDialogRef,
    private _cdr: ChangeDetectorRef,
    private _translationService: TranslationService,
    private _sanitizer: DomSanitizer

  ) {
    this.dialogTitle = this._templateData.dialogTitle;
    this.dialogMessage = this._templateData.isHtmlContent
      ? this._sanitizer.bypassSecurityTrustHtml(this._templateData.dialogMessage)
      : this._templateData.dialogMessage;
    this.primaryButtonTitle = this._templateData.primaryButtonTitle;
    this.secondaryButtonTitle = this._templateData.secondaryButtonTitle;
    this.isHtmlContent = this._templateData.isHtmlContent;
  }

  public ngOnInit(): void {
    this.showLoadingIndicator = false;
  }

  public ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public ngAfterViewChecked(): void {
    this._cdr.detectChanges();
  }

  public closeDialog(reload: boolean = false): void {
    this._dialogRef.close(reload);
  }

  public confirm(): void {
    this.showLoadingIndicator = true;
    this._templateData.confirm().subscribe({
      next: errorMessage => {
        this.showLoadingIndicator = false;

        if (errorMessage) {
          this.errorMessage = errorMessage;

          return;
        }

        this.closeDialog(true);
      },
      error: (errorResponse: ErrorResponse) => {
        this.showLoadingIndicator = false;

        this.errorMessage = {
          level: BannerMessageLevel.critical,
          title: this._translationService.translate(errorMessageConstants.UNEXPECTEDERROR, 'errors'),
          text1: errorResponse.error?.message ? this._translationService.translate(errorResponse.error?.message, 'errors') : ''
        };
        this.showLoadingIndicator = false;
      }
    });
  }
}
