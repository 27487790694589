import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationLink } from '../../models';

@Component({
  selector: 'lib-sub-menu-navbar',
  templateUrl: './sub-menu-navbar.component.html',
  styleUrls: ['./sub-menu-navbar.component.scss']
})
export class SubMenuNavbarComponent implements OnInit {

  @Input()
  public links: NavigationLink[] = [];

  @Input()
  public showButton = false;

  @Input()
  public buttonTitle = '';

  @Input()
  public showSecondButton = false;

  @Input()
  public secondButtonTitle = '';

  @Input()
  public translationGroup = 'navigation';

  @Input()
  public navigationId = '';


  @Output()
  public buttonClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public secondButtonClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  public ngOnInit(): void {
  }

  public onButtonClicked(): void {
    this.buttonClicked.next(null);
  }

  public onSecondButtonClicked(): void {
    this.secondButtonClicked.next(null);
  }

  public generateLinkPath(orgLinkPath: string): string {
    let linkPath = orgLinkPath.toString();
    const linkParts = linkPath.split('/');

    const idPart = linkParts.find(p => p.startsWith(':'));
    if (!!idPart && !!this.navigationId) {
      linkPath = linkPath.replace(idPart, this.navigationId);
    }

    return linkPath;
  }
}
