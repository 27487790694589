<div class="eshava-dialog {{dialogHeight}}-height {{dialogWidth}}-width"
     [@inOutAnimation]="animationRef.animationState()"
     (@inOutAnimation.done)="animationRef.animationDone($event)">
  <div class="eshava-dialog-indicator">
    <ng-content select="[indicator]"></ng-content>
  </div>
  <div class="eshava-dialog-container">
    <div class="eshava-dialog-header">{{ title }}</div>
    <div class="eshava-dialog-content">
      <div class="eshava-dialog-body-wrapper {{dialogHeight}}-height"
           [class.eshava-body-with-scroll-spy]="hasScrollSpy">
        <div class="eshava-shadow"
             [class.eshava-shadow-top]="bodyTopShadow"></div>
        <div class="eshava-shadow"
             [class.eshava-shadow-bottom]="bodyBottomShadow"></div>
        <div class="eshava-dialog-body {{dialogHeight}}-height"
             #modalBody
             (scroll)="onBodyScroll($event)">
          <ng-content select="[content]"></ng-content>
        </div>
      </div>
      <div *ngIf="hasScrollSpy"
           class="eshava-scroll-spy-wrapper">
        <div class="eshava-shadow"
             [class.eshava-shadow-top]="scrollSpyTopShadow"></div>
        <div class="eshava-shadow"
             [class.eshava-shadow-bottom]="scrollSpyBottomShadow"></div>
        <div class="eshava-modal-scroll-spy"
             #modalScrollSpy
             (scroll)="onScrollSpyScroll($event)">
          <ng-content select="[scrollspy]"></ng-content>
        </div>
      </div>
    </div>
  </div>
  <div class="eshava-dialog-footer">
    <ng-content select="[footer]"></ng-content>
  </div>
  <div class="eshava-dialog-banner">
    <ng-content select="[banner]"></ng-content>
  </div>
</div>
