/* eslint-disable @typescript-eslint/naming-convention */
export enum Permissions {
  NONE = 0,
  IGNORE_SUBSCRIPTION_ASSIGNMENT = 100,
  CAN_USE_OWN_CAMPANY_ACCOUNT = 101,
  CAN_USE_MODULE_APPLICATIONS = 1000,
  CAN_USE_MODULE_APPLICATIONS_SUBMODULE_APPLICATIONS = 1100,
  CAN_USE_MODULE_APPLICATIONS_SUBMODULE_APPLICATIONS_COMPONENT_APPLICATIONS = 1101,
  CAN_EDIT_MODULE_APPLICATIONS_SUBMODULE_APPLICATIONS_COMPONENT_APPLICATIONS = 1102,
  CAN_USE_MODULE_APPLICATIONS_SUBMODULE_APPLICATIONS_COMPONENT_APPLICATION_TYPES = 1103,
  CAN_EDIT_MODULE_APPLICATIONS_SUBMODULE_APPLICATIONS_COMPONENT_APPLICATION_TYPES = 1104,
  CAN_USE_MODULE_APPLICATIONS_SUBMODULE_APPLICATIONS_COMPONENT_SUBSCRIPTIONS = 1105,
  CAN_EDIT_MODULE_APPLICATIONS_SUBMODULE_APPLICATIONS_COMPONENT_SUBSCRIPTIONS = 1106,
  CAN_USE_MODULE_APPLICATIONS_SUBMODULE_APPLICATIONS_COMPONENT_MACHINE_TOKENS = 1107,
  CAN_EDIT_MODULE_APPLICATIONS_SUBMODULE_APPLICATIONS_COMPONENT_MACHINE_TOKENS = 1108,
  CAN_USE_MODULE_APPLICATIONS_SUBMODULE_APPLICATIONS_COMPONENT_SERVICE_HOOKS = 1109,
  CAN_EDIT_MODULE_APPLICATIONS_SUBMODULE_APPLICATIONS_COMPONENT_SERVICE_HOOKS = 1110,
  CAN_USE_MODULE_APPLICATIONS_SUBMODULE_ACCOUNTS = 1200,
  CAN_USE_MODULE_APPLICATIONS_SUBMODULE_ACCOUNTS_COMPONENT_ACCOUNTS = 1201,
  CAN_CREATE_APPLICATIONS_ACCOUNTS = 1202,
  CAN_EDIT_APPLICATIONS_ACCOUNTS = 1210,
  CAN_DELETE_APPLICATIONS_ACCOUNTS = 1203,
  CAN_USE_MODULE_APPLICATIONS_SUBMODULE_ACCOUNT_DETAILS = 1204,
  CAN_USE_MODULE_APPLICATIONS_SUBMODULE_ACCOUNT_DETAILS_COMPONENT_COMMON = 1205,
  CAN_USE_MODULE_APPLICATIONS_SUBMODULE_ACCOUNT_DETAILS_COMPONENT_ROLES = 1206,
  CAN_EDIT_MODULE_APPLICATIONS_SUBMODULE_ACCOUNT_DETAILS_COMPONENT_ROLES = 1207,
  CAN_USE_MODULE_APPLICATIONS_SUBMODULE_ACCOUNT_DETAILS_COMPONENT_USERS = 1208,
  CAN_EDIT_MODULE_APPLICATIONS_SUBMODULE_ACCOUNT_DETAILS_COMPONENT_USERS = 1209,
  CAN_USE_MODULE_APPLICATIONS_SUBMODULE_USERS = 1300,
  CAN_USE_MODULE_APPLICATIONS_SUBMODULE_USERS_COMPONENT_ACCOUNTS = 1301,
  CAN_USE_MODULE_APPLICATIONS_SUBMODULE_USERS_COMPONENT_SYSTEM = 1302,
  CAN_EDIT_MODULE_APPLICATIONS_SUBMODULE_USERS_COMPONENT_SYSTEM = 1303,
  CAN_USE_MODULE_PERMISSIONS = 2000,
  CAN_USE_MODULE_PERMISSIONS_SUBMODULE_LICENCES = 2100,
  CAN_USE_MODULE_PERMISSIONS_SUBMODULE_LICENCES_COMPONENT_LICENSES = 2101,
  CAN_EDIT_MODULE_PERMISSIONS_SUBMODULE_LICENCES_COMPONENT_LICENSES = 2102,
  CAN_USE_MODULE_PERMISSIONS_SUBMODULE_LICENCES_COMPONENT_LICENSE_TYPES = 2103,
  CAN_EDIT_MODULE_PERMISSIONS_SUBMODULE_LICENCES_COMPONENT_LICENSE_TYPES = 2104,
  CAN_USE_MODULE_PERMISSIONS_SUBMODULE_MODULES = 2200,
  CAN_USE_MODULE_PERMISSIONS_SUBMODULE_MODULES_COMPONENT_MODULES = 2201,
  CAN_EDIT_MODULE_PERMISSIONS_SUBMODULE_MODULES_COMPONENT_MODULES = 2202,
  CAN_USE_MODULE_PERMISSIONS_SUBMODULE_MODULES_COMPONENT_SUBMODULES = 2203,
  CAN_EDIT_MODULE_PERMISSIONS_SUBMODULE_MODULES_COMPONENT_SUBMODULES = 2204,
  CAN_USE_MODULE_PERMISSIONS_SUBMODULE_MODULES_COMPONENT_COMPONENTS = 2205,
  CAN_EDIT_MODULE_PERMISSIONS_SUBMODULE_MODULES_COMPONENT_COMPONENTS = 2206,
  CAN_USE_MODULE_PERMISSIONS_SUBMODULE_MODULES_COMPONENT_PERMISSIONS = 2207,
  CAN_EDIT_MODULE_PERMISSIONS_SUBMODULE_MODULES_COMPONENT_PERMISSIONS = 2208,
  CAN_USE_MODULE_PERMISSIONS_SUBMODULE_ROLE_TEMPLATES = 2300,
  CAN_USE_MODULE_PERMISSIONS_SUBMODULE_ROLE_TEMPLATES_COMPONENT_ROLES = 2301,
  CAN_EDIT_MODULE_PERMISSIONS_SUBMODULE_ROLE_TEMPLATES_COMPONENT_ROLES = 2302,
  CAN_USE_MODULE_TENANTS = 3000,
  CAN_USE_MODULE_TENANTS_SUBMODULE_TENANTS = 3100,
  CAN_USE_MODULE_TENANTS_SUBMODULE_TENANTS_COMPONENT_TENANTS = 3101,
  CAN_CREATE_TENANTS = 3102,
  CAN_EDIT_TENANTS = 3107,
  CAN_DELETE_TENANTS = 3103,
  CAN_USE_MODULE_TENANTS_SUBMODULE_TENANT_DETAILS = 3104,
  CAN_USE_MODULE_TENANTS_SUBMODULE_TENANT_DETAILS_COMPONENT_BASIC_DATA = 3105,
  CAN_USE_MODULE_TENANTS_SUBMODULE_TENANT_DETAILS_COMPONENT_ACCOUNTS = 3106,
  CAN_USE_MODULE_USERS = 4000,
  CAN_USE_MODULE_USERS_SUBMODULE_USER_ACCOUNTS = 4100,
  CAN_USE_MODULE_USERS_SUBMODULE_USER_ACCOUNTS_COMPONENT_USER_ACCOUNTS = 4101,
  CAN_USE_MODULE_USERS_SUBMODULE_USER_ACCOUNT_DETAILS = 4102,
  CAN_USE_MODULE_USERS_SUBMODULE_USER_ACCOUNT_DETAILS_COMPONENT_BASIC_DATA = 4103,
  CAN_EDIT_USER_ACCOUNT_BASIC_DATA = 4104,
  CAN_USE_MODULE_USERS_SUBMODULE_USER_ACCOUNT_DETAILS_COMPONENT_SUBSCRIPTIONS = 4105,
  CAN_EDIT_USER_ACCOUNT_SUBSCRIPTIONS = 4106,
  CAN_USE_MODULE_INFRASTRUCTURE = 5000,
  CAN_USE_MODULE_INFRASTRUCTURE_SUBMODULE_NOTIFICATIONS = 5100,
  CAN_USE_MODULE_INFRASTRUCTURE_SUBMODULE_NOTIFICATIONS_COMPONENT_LOG_ENTRIES = 5101,
  CAN_USE_MODULE_INFRASTRUCTURE_SUBMODULE_SCHEDULER = 5200,
  CAN_USE_MODULE_INFRASTRUCTURE_SUBMODULE_SCHEDULER_COMPONENT_EVENTS = 5201,
  CAN_EDIT_SCHEDULER_EVENTS = 5202,
  CAN_USE_MODULE_INFRASTRUCTURE_SUBMODULE_SCHEDULER_COMPONENT_EVENT_TYPES = 5203,
  CAN_EDIT_SCHEDULER_EVENT_TYPES = 5204,
}
