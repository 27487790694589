<div [class.eshava-active]="active"
     class="eshava-wrapper">
  <label class="eshava-title">{{title}}</label>
  <div class="eshava-border-left">
    <div class="eshava-container">
      <ng-content>
      </ng-content>
    </div>
  </div>
</div>
