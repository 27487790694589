import { Environment } from './environment.type';

export const environment: Environment = {
  production: false,
  stage: 'development',
  oidc: {
    authority: 'https://dev-cardinal.eshava.de',
    audience: 'https://dev-api.eshava.de',
    clientId: 'Eshava.UI.Angular.Core-Dev',
    requireHttps: true,
    scope: 'openid profile email services.api.read'
  },
  baseUrls: {
    cardinalUrl: 'https://dev-cardinal.eshava.de',
    dataStorageUrl: 'https://dev-api.eshava.de/datastorage',
    loggingUrl: 'https://dev-api.eshava.de/logging',
    schedulerUrl: 'https://dev-api.eshava.de/scheduler',
    serviceBusUrl: 'https://dev-api.eshava.de/servicebus',
    uiUrl: 'https://dev-core.eshava.de'
  }
};
