import { Component, Input, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EshavaScrollSpyItem } from '../../models';

@Component({
  selector: 'lib-eshava-scroll-spy',
  templateUrl: './eshava-scroll-spy.component.html',
  styleUrls: ['./eshava-scroll-spy.component.scss']
})
export class EshavaScrollSpyComponent implements OnDestroy {

  private _subscription = new Subscription();

  public url: string;

  @Input()
  public scrollSpyItems!: EshavaScrollSpyItem[];

  @Input()
  public intersectionName = 'intersectionRoot';

  constructor(
    private _router: Router
  ) {
    this._subscription.add(
      this._router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.url = this.getCurrentUrl();
        }
      })
    );
    this.url = this.getCurrentUrl();
  }

  public ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  public onClick(event: MouseEvent, elementId: string): void {
    // Avoid generating url with anchor
    event.preventDefault();

    // Scroll directly
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
        inline: 'start'
      });
    }
  }

  private getCurrentUrl(): string {
    const sectionIndex = this._router.url.lastIndexOf('#');

    return sectionIndex >= 0
      ? this._router.url.substring(0, sectionIndex)
      : this._router.url;
  }
}
