import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { EshavaControlsModule } from 'eshava-controls';
import { EshavaGridModule } from 'eshava-grid';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ComponentLoadingIndicatorComponent, ConfirmDialogComponent, ContextMenuComponent, EshavaDialogSeparatorComponent, EshavaScrollSpyComponent, EshavaSingleDialogComponent, MessageBannerComponent, SubMenuNavbarComponent } from './components';
import { TranslatePipe } from './pipes';
import { EshavaScrollSpyListItemDirective, EshavaScrollSpyTargetDirective, HasPermissionDirective } from './directives';

registerLocaleData(localeDe, localeDeExtra);

@NgModule({
  declarations: [
    TranslatePipe,
    ComponentLoadingIndicatorComponent,
    ContextMenuComponent,
    MessageBannerComponent,
    SubMenuNavbarComponent,
    EshavaSingleDialogComponent,
    ConfirmDialogComponent,
    EshavaScrollSpyComponent,
    EshavaDialogSeparatorComponent,
    EshavaScrollSpyListItemDirective,
    EshavaScrollSpyTargetDirective,
    HasPermissionDirective
  ],
  imports: [
    FormsModule,
    CommonModule,
    RouterModule.forChild([]),
    AngularSvgIconModule.forRoot(),
    EshavaGridModule.forRoot(),
    EshavaControlsModule.forRoot(),
    OverlayModule,
    ImageCropperModule
  ],
  exports: [
    FormsModule,
    CommonModule,
    RouterModule,
    OverlayModule,
    AngularSvgIconModule,
    EshavaGridModule,
    EshavaControlsModule,
    TranslatePipe,
    ComponentLoadingIndicatorComponent,
    ContextMenuComponent,
    MessageBannerComponent,
    SubMenuNavbarComponent,
    EshavaSingleDialogComponent,
    ConfirmDialogComponent,
    EshavaScrollSpyComponent,
    EshavaDialogSeparatorComponent,
    EshavaScrollSpyListItemDirective,
    EshavaScrollSpyTargetDirective,
    HasPermissionDirective
  ],
  providers: [
    TranslatePipe,
    CurrencyPipe,
    // DecimalPipe
  ]
})
export class CoreSharedModule { }
