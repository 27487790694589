import { EventEmitter, Injectable, Output } from '@angular/core';
import { Route } from '@angular/router';
import { MenuItem } from '../models';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private _menuItems: MenuItem[] = [];

  public get menuItems(): MenuItem[] {
    return this._menuItems;
  }

  @Output()
  public menuItemsChanged$: EventEmitter<MenuItem[]> = new EventEmitter<MenuItem[]>();

  constructor(
    private _authenticationService: AuthenticationService
  ) { }

  public setNavigationItems(items: Route[]): void {
    this._menuItems = [];

    items.forEach(item => {
      if (item.data && item.path && (!(item.data as any).only || this._authenticationService.hasPermission((item.data as any).only))) {
        const newItem: MenuItem = this.buildMenuItem(item);
        if ((item.data as any)?.isMainNavigation) {
          this._menuItems.push(newItem);
        }
      }
    });

    this.menuItemsChanged$.emit(this._menuItems);
  }

  private buildMenuItem(item: Route): MenuItem {
    const newItem: MenuItem = {
      path: item.path || '',
      titleKey: (item.data as any).titleKey,
      isHidden: (item.data as any).isHidden,
      icon: (item.data as any).icon,
      children: [],
      isMainNavigation: (item.data as any).isMainNavigation
    };

    if (item.children) {
      item.children.forEach(c => {
        if (c.data) {
          newItem.children?.push(this.buildMenuItem(c));
        }
      });
    }

    newItem.children = newItem.children?.filter(c => !c.isHidden);

    return newItem;
  }
}
