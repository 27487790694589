import { animate, state, style, transition, trigger } from '@angular/animations';

export const eshavaDialogInOutAnimation = trigger('inOutAnimation', [
  transition(':enter', [
    style({ transform: 'scale(0.95)', opacity: 0 }),
    animate('0.15s ease-in', style({ transform: '*', opacity: '*' }))
  ]),
  state('hide', style({ transform: 'scale(0.95)', opacity: 0 })),
  transition('show => hide', [animate('0.15s ease-in')])
]);
