<div class="sub-menu-navbar-container">
  <ng-container *ngFor="let link of links; index as i">
    <a class="sub-menu-navbar-item item-{{i}}"
       [routerLink]="generateLinkPath(link.path)"
       routerLinkActive="active"
       title="{{ link.data!.titleKey | translate: translationGroup }}">
      <span>{{ link.data!.titleKey | translate: translationGroup }}</span>
      <span *ngIf="link.data?.count"
            class="badge badge-pill me-2">&nbsp;{{link.data!.count}}&nbsp;</span>
    </a>
  </ng-container>
  <div *ngIf="(showButton && buttonTitle) || (showSecondButton && secondButtonTitle)"
       class="sub-menu-navbar-button item-5">
    <ng-container *ngIf="showButton && buttonTitle">
      <button type="button"
              class="eshava-btn eshava-btn-primary"
              (click)="onButtonClicked()">{{buttonTitle}}</button>
    </ng-container>
    <ng-container *ngIf="showSecondButton && secondButtonTitle">
      <button type="button"
              class="eshava-btn eshava-btn-primary ms-2"
              (click)="onSecondButtonClicked()">{{secondButtonTitle}}</button>
    </ng-container>
  </div>
</div>