import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, Input, TemplateRef, ViewChild } from '@angular/core';
import { EshavaDialogAnimationRef, eshavaDialogInOutAnimation } from '../../models';

@Component({
  selector: 'lib-eshava-single-dialog',
  templateUrl: './eshava-single-dialog.component.html',
  styleUrls: ['./eshava-single-dialog.component.scss'],
  animations: [eshavaDialogInOutAnimation]
})
export class EshavaSingleDialogComponent implements AfterViewInit {

  public bodyTopShadow = false;
  public bodyBottomShadow = false;

  public scrollSpyTopShadow = false;
  public scrollSpyBottomShadow = false;

  @Input()
  public template!: TemplateRef<object>;

  @Input()
  public hasScrollSpy = false;

  @Input()
  public title: string = '';

  @Input()
  public dialogHeight: 'static' | 'dynamic' = 'static'

  @Input()
  public dialogWidth: 'default' | 'small' = 'default'

  @ViewChild('modalBody')
  public body!: ElementRef;
  @ViewChild('modalScrollSpy')
  public scrollSpy!: ElementRef;

  constructor(private _cdr: ChangeDetectorRef, @Inject(EshavaDialogAnimationRef) public animationRef: EshavaDialogAnimationRef) { }

  public ngAfterViewInit(): void {
    this.calcShadowsOnBody(this.body.nativeElement);
    if (this.hasScrollSpy) {
      this.calcShadowsOnScrollSpy(this.scrollSpy.nativeElement);
    }
    this._cdr.detectChanges();
  }


  public onBodyScroll(event: any): void {
    this.calcShadowsOnBody(event.target);
  }

  public onScrollSpyScroll(event: any): void {
    this.calcShadowsOnScrollSpy(event.target);
  }

  private calcShadowsOnBody(event: any): void {
    this.bodyTopShadow = event.scrollTop > 0;
    this.bodyBottomShadow = event.scrollTop + event.clientHeight < event.scrollHeight;
  }

  private calcShadowsOnScrollSpy(event: any): void {
    this.scrollSpyTopShadow = event.scrollTop > 0;
    this.scrollSpyBottomShadow = event.scrollTop + event.clientHeight < event.scrollHeight;
  }
}
