export class EshavaDialogAnimationRef {

  private _shouldAnimateExit?: () => void;
  private _animationState: 'show' | 'hide' = 'show';

  public shouldAnimateExit(): Promise<void> {
    this._animationState = 'hide';

    return new Promise<void>(res => this._shouldAnimateExit = res);
  }

  public animationDone($event: any): void {
    if ($event.toState === 'hide' && this._shouldAnimateExit) {
      this._shouldAnimateExit();
    }
  }

  public animationState(): string {
    return this._animationState;
  }
}
