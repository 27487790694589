import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CoreSharedModule, AuthInterceptor, MenuService, TranslationService } from '@core/shared';
import { OAuthModule } from 'angular-oauth2-oidc';
import { ToastrModule } from 'ngx-toastr';

import { environment } from '../environments/environment';
import { initApp } from './app-functions';
import { AppRoutingModule, mainNavigationRoutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './modules/core/core.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    CoreSharedModule,
    CoreModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [
          environment.baseUrls.cardinalUrl,
          environment.baseUrls.dataStorageUrl,
          environment.baseUrls.loggingUrl,
          environment.baseUrls.schedulerUrl,
          environment.baseUrls.serviceBusUrl,
        ],
        sendAccessToken: true
      }
    }),
    ToastrModule.forRoot({
      maxOpened: 10,
      newestOnTop: true,
      preventDuplicates: true,
      includeTitleDuplicates: true,
      positionClass: 'toast-top-right'
    })
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initApp, deps: [TranslationService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() { }
}
